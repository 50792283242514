import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      site {
        siteMetadata {
          author {
            name
          }
          social {
            twitter
            instagram
            linkedin
          }
        }
      }
      picture: file( relativePath: { eq: "profile-pic.jpg" } ) {
        childImageSharp{
          fixed(width: 64, height: 64) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const { author, social } = data.site.siteMetadata
  return (
    <div
      style={{
        display: `flex`,
      }}
    >
      <Img fixed={data.picture.childImageSharp.fixed} className="d-inline-block align-top rounded-circle border border-dark mr-3" alt="Alexander Petkov Portrait" />
      <div>
        <p className="lead">
          Written by <strong>{author.name}</strong><br />
          Founder & CEO of <a href="https://www.celantur.com">Celantur</a>, Tech Enthusiast, Citoyen.
        </p>
        <p>
          Follow him on{` `}
          <a href={`https://linkedin.com/in/${social.linkedin}`}>LinkedIn</a>,{` `}
          <a href={`https://instagram.com/${social.instagram}`}>Instagram</a> and {` `}
          <a href={`https://twitter.com/${social.twitter}`}>Twitter</a>.
        </p>
      </div>
    </div>
  )
}

export default Bio
